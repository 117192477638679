import React from "react";
import Carousle from "../Carousle/Carousle";

function Home() {
    return (
            <div className="container-fluid">
                <Carousle/>
            </div>

    );

}

export default Home;